import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Container from '../../../Components/Container';
import styles from './styles.module.scss';
import Image from "next/image";

import Link from 'next/link';
const Footer = (props) => {
  return (
    <footer className={styles.footer} id="mainFooter">
      <Container>
        <Row className={styles.sections}>
          <Col className="col-md-6">
            <div className={styles.logo}>
              <Link href={'/'}>
                <a>
                  <Image
                    src="/assets/img/logo/final-logo.png"
                    alt="NFTON Logo"
                    width="115"
                    height="50"
                  />
                </a>
              </Link>
            </div>
          </Col>
          <Col
            style={{
              marginTop: '-2%',
              zIndex : 1000
            }}
            className="col-md-3"
          >
            <h6 className={styles.title}>Company</h6>
            <div className={styles.nav}>
              <Link href="/about-us">
                <a href={`${process.env.NEXT_PUBLIC_WEB_URL}/about-us`}>
                  About
                </a>
              </Link>
              <Link href="/contact-us">
                <a href={`${process.env.NEXT_PUBLIC_WEB_URL}/contact-us`}>
                  Contact
                </a>
              </Link>
              <Link href="/blog">
                <a href={`${process.env.NEXT_PUBLIC_WEB_URL}/blog`}>Blog</a>
              </Link>
            </div>
          </Col>
          <Col
            style={{
              marginTop: '-2%',
              zIndex : 1000
            }}
            className="col-md-3"
          >
            <h6 className={styles.title}>Support</h6>
            <div className={styles.nav}>
              <Link href="/faq">
                <a href={`${process.env.NEXT_PUBLIC_WEB_URL}/faq`}>FAQ</a>
              </Link>
              <Link href="/terms-of-services">
                <a href={`${process.env.NEXT_PUBLIC_WEB_URL}/terms-of-service`}>
                  Terms of Service
                </a>
              </Link>
              <Link href="/privacy-policy">
                <a href={`${process.env.NEXT_PUBLIC_WEB_URL}/privacy-policy`}>
                  Privacy Policy
                </a>
              </Link>
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            style={{
              marginTop: '-8%',
            }}
            className="col-md-12"
          >
            <div className={styles.content}>
              <div className={styles.social}>
                <a
                  href="https://www.facebook.com/nfton/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M20.9,2H3.1A1.1,1.1,0,0,0,2,3.1V20.9A1.1,1.1,0,0,0,3.1,22h9.58V14.25h-2.6v-3h2.6V9a3.64,3.64,0,0,1,3.88-4,20.26,20.26,0,0,1,2.33.12v2.7H17.3c-1.26,0-1.5.6-1.5,1.47v1.93h3l-.39,3H15.8V22h5.1A1.1,1.1,0,0,0,22,20.9V3.1A1.1,1.1,0,0,0,20.9,2Z" />
                  </svg>
                </a>
                <a
                  href="https://twitter.com/nftonmarket"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M22,5.8a8.49,8.49,0,0,1-2.36.64,4.13,4.13,0,0,0,1.81-2.27,8.21,8.21,0,0,1-2.61,1,4.1,4.1,0,0,0-7,3.74A11.64,11.64,0,0,1,3.39,4.62a4.16,4.16,0,0,0-.55,2.07A4.09,4.09,0,0,0,4.66,10.1,4.05,4.05,0,0,1,2.8,9.59v.05a4.1,4.1,0,0,0,3.3,4A3.93,3.93,0,0,1,5,13.81a4.9,4.9,0,0,1-.77-.07,4.11,4.11,0,0,0,3.83,2.84A8.22,8.22,0,0,1,3,18.34a7.93,7.93,0,0,1-1-.06,11.57,11.57,0,0,0,6.29,1.85A11.59,11.59,0,0,0,20,8.45c0-.17,0-.35,0-.53A8.43,8.43,0,0,0,22,5.8Z" />
                  </svg>
                </a>
              </div>
              <p className={styles.copyright}>
                &copy; {new Date().getFullYear()}. All rights reserved. A
                Ransoft SRL Company.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
