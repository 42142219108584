import React from 'react';
import {useMediaQuery} from "../../../Hooks/useMediaQuery"
import Footer from './Footer'
import MobileFooter from './MobileFooter';

const FooterPage = (props) => {
  const mobileScreen = useMediaQuery("(max-width:684px)");
  return (
    <>
    {!mobileScreen && <Footer />}
    {mobileScreen && <MobileFooter />}
    </>
  );
};

export default FooterPage;
